"use strict";

let header = document.querySelectorAll("header")[0],
	scrollPosition = 0;

// https://www.joshwcomeau.com/snippets/javascript/debounce/.
const debounce = (callback, wait = 100) => {
	let timeoutId = null;

	return (...args) => {
		window.clearTimeout(timeoutId);
		timeoutId = window.setTimeout(() => {
			callback.apply(null, args);
		}, wait);
	};
};

// Handle mobile header's class based on scroll position including page load.
let handleMobileHeader = debounce(() => {
	scrollPosition = window.scrollY;

	if (scrollPosition >= 1) {
		header.classList.add("mobile-header-scrolled");
	} else {
		header.classList.remove("mobile-header-scrolled");
	}
}, 100);

window.addEventListener("load", handleMobileHeader);
window.addEventListener("scroll", handleMobileHeader);



// Replace video poster image with video
let originalPosterImage = document.querySelectorAll(".pp-video-image-overlay img[title='video-poster']");

function replaceVideoPoster() {
	originalPosterImage.forEach(posterImage => {
		let newItem = document.createElement("div");
		newItem.innerHTML = "<video class='pp-video-player' src='/wp-content/uploads/2022/11/ROI-Commercial-Cut-w-endcard_compressed.mp4' autoplay muted loop></video>";
		posterImage.parentNode.replaceChild(newItem, posterImage);
	});
}
// Home page only
if (document.body.classList.contains("home")) {
	window.addEventListener("load", replaceVideoPoster);
}



// Remove title tag from callout image
let calloutImages = document.querySelectorAll(".fl-callout .fl-photo-content img");

function removeCalloutImageTitleTag() {
	calloutImages.forEach(calloutImage => {
		calloutImage.removeAttribute("title");
	});
}
// Home page only
if (document.body.classList.contains("home")) {
	window.addEventListener("load", removeCalloutImageTitleTag);
}



// Disable menu nav clicks to nav
let navIndustriesHeader = document.querySelector(".site__header__nav a[href$='/industries/']"),
	navIndustriesFooter = document.querySelector(".site__footer__nav a[href$='/industries/']");
navIndustriesHeader.addEventListener("click", function(e) {
	e.preventDefault();
	navIndustriesHeader.removeAttribute("href");
});
navIndustriesFooter.addEventListener("click", function(e) {
	e.preventDefault();
	navIndustriesFooter.removeAttribute("href");
});



// Accordion Image Switcher
let accordionItem1 = document.querySelectorAll(".custom__accordion .fl-accordion-item")[0],
	accordionItem2 = document.querySelectorAll(".custom__accordion .fl-accordion-item")[1],
	accordionItem3 = document.querySelectorAll(".custom__accordion .fl-accordion-item")[2],
	accordionItem4 = document.querySelectorAll(".custom__accordion .fl-accordion-item")[3],
	accordionItem5 = document.querySelectorAll(".custom__accordion .fl-accordion-item")[4],
	accordionItem6 = document.querySelectorAll(".custom__accordion .fl-accordion-item")[5];
// Services or Furniture Solutions pages only
if (document.body.classList.contains("page-id-460") || document.body.classList.contains("page-id-461")) {
	accordionItem1.addEventListener("click", function() {
		document.querySelectorAll(".accordion__image__wrapper .accordion__image").forEach(image => {image.classList.remove("accordion__image--shown");});
		document.querySelectorAll(".accordion__image__wrapper .accordion__image")[0].classList.add("accordion__image--shown");
	});
	accordionItem2.addEventListener("click", function() {
		document.querySelectorAll(".accordion__image__wrapper .accordion__image").forEach(image => {image.classList.remove("accordion__image--shown");});
		document.querySelectorAll(".accordion__image__wrapper .accordion__image")[1].classList.add("accordion__image--shown");
	});
	if (accordionItem3) {
		accordionItem3.addEventListener("click", function() {
			document.querySelectorAll(".accordion__image__wrapper .accordion__image").forEach(image => {image.classList.remove("accordion__image--shown");});
			document.querySelectorAll(".accordion__image__wrapper .accordion__image")[2].classList.add("accordion__image--shown");
		});
	}
	if (accordionItem4) {
		accordionItem4.addEventListener("click", function() {
			document.querySelectorAll(".accordion__image__wrapper .accordion__image").forEach(image => {image.classList.remove("accordion__image--shown");});
			document.querySelectorAll(".accordion__image__wrapper .accordion__image")[3].classList.add("accordion__image--shown");
		});
	}
	if (accordionItem5) {
		accordionItem5.addEventListener("click", function() {
			document.querySelectorAll(".accordion__image__wrapper .accordion__image").forEach(image => {image.classList.remove("accordion__image--shown");});
			document.querySelectorAll(".accordion__image__wrapper .accordion__image")[4].classList.add("accordion__image--shown");
		});
	}
	if (accordionItem6) {
		accordionItem6.addEventListener("click", function() {
			document.querySelectorAll(".accordion__image__wrapper .accordion__image").forEach(image => {image.classList.remove("accordion__image--shown");});
			document.querySelectorAll(".accordion__image__wrapper .accordion__image")[5].classList.add("accordion__image--shown");
		});
	}
}



// People modal
let modal = document.querySelector(".peopleModal"),
	fl_page = document.querySelector(".fl-page");
// About page only
if (document.body.classList.contains("page-id-462")) {
	
	// Moves modal to just before closing body tag, to be on top of other elements
	fl_page.appendChild(document.querySelector(".peopleModal"));

	document.querySelectorAll(".people-listing .person").forEach(item => {
		item.addEventListener("click", event => {
			document.documentElement.style.overflowY = "hidden";
			modal.classList.add("peopleModal-open");
			modal.querySelector(".peopleModal-image img").setAttribute("src", item.getAttribute("data-photo-alt"));
			modal.querySelector(".peopleModal-image img").setAttribute("alt", item.getAttribute("data-name"));
			modal.querySelector(".peopleModal-info .name").innerHTML = item.getAttribute("data-name");
			if (typeof item.getAttribute("data-email") == "string" && item.getAttribute("data-email").length) {
				modal.querySelector(".peopleModal-info .email a").innerHTML = item.getAttribute("data-email");
				modal.querySelector(".peopleModal-info .email a").setAttribute("href", "mailto:" + item.getAttribute("data-email"));
			} else {
				modal.querySelector(".peopleModal-info .email").style.display = "none";
			}
			if (typeof item.getAttribute("data-phone") == "string" && item.getAttribute("data-phone").length) {
				modal.querySelector(".peopleModal-info .phone a").innerHTML = item.getAttribute("data-phone");
				modal.querySelector(".peopleModal-info .phone a").setAttribute("href", "tel:" + item.getAttribute("data-phone").replace(/\D/g, ""));
			} else {
				modal.querySelector(".peopleModal-info .phone").style.display = "none";
			}
			modal.querySelector(".peopleModal-info .bio").innerHTML = item.getAttribute("data-bio");
		});
	});

	// Click outside of the modal
	window.onclick = function(event) {
		if (event.target == modal) {
			document.documentElement.style.overflowY = "revert";
			modal.classList.remove("peopleModal-open");
			setTimeout(() => {
				// clear fields
				modal.querySelector(".peopleModal-info .name").innerHTML = "";
				modal.querySelector(".peopleModal-info .bio").innerHTML = "";
				modal.querySelector(".peopleModal-info .email a").innerHTML = "";
				modal.querySelector(".peopleModal-info .phone a").innerHTML = "";
				// show if hidden
				modal.querySelector(".peopleModal-info .email").style.display = "block";
				modal.querySelector(".peopleModal-info .phone").style.display = "block";
			}, 100);
		}
	};
}
function closePeopleModal() {
	document.documentElement.style.overflowY = "revert";
	modal.classList.remove("peopleModal-open");
	setTimeout(() => {
		// clear fields
		modal.querySelector(".peopleModal-info .name").innerHTML = "";
		modal.querySelector(".peopleModal-info .bio").innerHTML = "";
		modal.querySelector(".peopleModal-info .email a").innerHTML = "";
		modal.querySelector(".peopleModal-info .phone a").innerHTML = "";
		// show if hidden
		modal.querySelector(".peopleModal-info .email").style.display = "block";
		modal.querySelector(".peopleModal-info .phone").style.display = "block";
	}, 100);
}
